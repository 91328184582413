import { useContext, createContext } from 'react';

import i18nConfig from 'next-i18next.config.js';

const { i18n } = i18nConfig;

export interface ContentfulContextInterface {
  locale: string;
}

export const contentfulContextValue: ContentfulContextInterface = {
  locale: i18n.defaultLocale,
};

export const ContentfulContext = createContext<ContentfulContextInterface>(contentfulContextValue);

export const useContentfulContext = () => useContext(ContentfulContext);

const ContentfulContentProvider = ({ children, router }: {children: any; router: any}) => {

  return (
    <ContentfulContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        locale: typeof router.locale === 'string' ? router.locale : i18n.defaultLocale,
      }}>
      {children}
    </ContentfulContext.Provider>
  );
};

export { ContentfulContentProvider };
