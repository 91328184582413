import CookieBanner from './cookieBanner';

interface LayoutPropsIC {
  // eslint-disable-next-line react/require-default-props
  children?: JSX.Element[] | JSX.Element;
}

const Layout = ({ children }: LayoutPropsIC) => {
  return (
    <>
      <CookieBanner />
      {children}
    </>
  );
};
export default Layout;
