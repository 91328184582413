import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';

interface OSContextType {
  os: string;
}

interface OSProviderProps {
  children?: ReactNode;
}

const OSContext = createContext<OSContextType | undefined>(undefined);

export const useOSContext = () => {
  const context = useContext(OSContext);
  return context;
};

export const OSProvider: React.FC<OSProviderProps> = ({ children }) => {
  const [os, setOs] = useState<string>('Unknown');

  useEffect(() => {
    setOs(fetchOsData());
  }, []);

  return <OSContext.Provider value={{ os }}>{children}</OSContext.Provider>;
};

function fetchOsData() {
  const userAgent = navigator.userAgent;
  let os = 'Unknown';
  if (userAgent.match(/Windows/i)) {
    os = 'Windows';
  } else if (userAgent.match(/Mac/i)) {
    os = 'MacOS';
  } else if (userAgent.match(/Linux/i)) {
    os = 'Linux';
  }
  return os;
}
