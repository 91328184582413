import Layout from '@/components/layout';
import { ContentfulContentProvider } from '@/contentful-context';
import { OSProvider } from '@/context/OSContext';
import { queryConfig } from '@/lib/gql-client';
import {
  DehydratedState,
  Hydrate,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { getCookie } from 'cookies-next';
import { SSRConfig, appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../i18n';
import '../styles/globals.css';

type CustomPageProps = SSRConfig & {
  dehydratedState: DehydratedState;
  err: Error;
};

const App = ({
  Component,
  router,
  pageProps: originalPageProps
}: AppProps<CustomPageProps>) => {
  const [queryClient] = useState(() => new QueryClient(queryConfig));
  const { dehydratedState, err, ...pageProps } = originalPageProps;
  const consentAnalytics = getCookie('analytics');
  const { locale } = useRouter();
  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale).then(() => {
        document.documentElement.lang = locale;
        document.documentElement.dir = i18n.dir(locale);
      });
    }
  }, []);
  useEffect(() => {
    const handleRouteChange = ({ shallow }: any) => {
      if (!shallow) {
        window.sessionStorage.setItem('scrollPos', window.scrollY.toString());
      }
    };

    const handleBeforeUnload = () => {
      window.sessionStorage.setItem('scrollPos', window.scrollY.toString());
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [router.events]);

  useEffect(() => {
    const scrollPos = parseInt(
      window.sessionStorage.getItem('scrollPos') || '0',
      10
    );
    window.scrollTo(0, scrollPos);
  }, []);
  return (
    <OSProvider>
      <ContentfulContentProvider router={router}>
        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-SL1FC7B7VD"
        />
        <Script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
               gtag('consent', 'default', {
              'ad_storage': 'denied',
              'analytics_storage': 'denied'
            });
              gtag('js', new Date());
              gtag('config', 'G-SL1FC7B7VD');
            `
          }}
        />

        {consentAnalytics === true && (
          <Script
            id="consupd"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            gtag('consent', 'update', {
              'ad_storage': 'granted',
              'analytics_storage': 'granted'
            });
          `
            }}
          />
        )}

        <QueryClientProvider client={queryClient}>
          <Hydrate state={dehydratedState}>
            <I18nextProvider i18n={i18n}>
              <Layout>
                <ToastContainer
                  className="toast-style"
                  limit={5}
                  transition={Slide}
                />
                <Component {...pageProps} err={err} />
              </Layout>
            </I18nextProvider>
          </Hydrate>
          <ReactQueryDevtools initialIsOpen />
        </QueryClientProvider>
      </ContentfulContentProvider>
    </OSProvider>
  );
};

export default appWithTranslation(App);
